import React from 'react'
import Content from '../Content'
import { kebabCase } from 'lodash'
import { Link } from 'gatsby'

const ArticleTemplate = ({
  content,
  contentComponent,
  cover,
  meta_title,
  meta_desc,
  tags,
  title,
  spoiler,
}) => {
  const PostContent = contentComponent || Content
  return (
    <div>
      <h1 className='title is-size-2 has-text-weight-bold is-bold-light'>
        {title}
      </h1>
      <p>{spoiler}</p>
      <div>
        <figure>
          <img src={cover} alt={title} />
        </figure>
        <hr />
      </div>
      <PostContent content={content} />

      <hr />
      <blockquote>
        <p>
          Napisz komentarz jeśli na naszej liście brakuje jakiegoś elementu.
        </p>
      </blockquote>
      <blockquote>
        <p>
          Wyślij swoją listę przez
          <Link to='/kontakt/'> formularz</Link>
        </p>
      </blockquote>

      <h2>Zobacz także dodatkowe listy tematyczne</h2>
      <ul>
        {/* <li>Standadowa lista na wyjazd kilkudniowy</li> */}
        {/* <li>Standadowa lista na wyjazd z dziećmi</li> */}
        <li>
          <Link to='co-zabrac/wyjazd-samochodem/'>Lista na wyjazd samochodem</Link>
        </li>
        <li>
          <Link to='co-zabrac/wyjazd-zagraniczny/'>Lista na wyjazd zagraniczny</Link>
        </li>
      </ul>

      <div style={{ marginTop: `4rem` }}>
        <h4>Tags</h4>
        <ul className='taglist'>
          {tags && tags.length
            ? tags.map(tag => (
              <li key={tag + `tag`}>
                <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
              </li>
            ))
            : null}
        </ul>
      </div>
      <hr />
    </div>
  )
}

export default ArticleTemplate
